handlePaginationClick = function (event, domId, remote) {
  if (remote) {
    event.preventDefault();
    const url = event.currentTarget.href;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        document.querySelector(`#${domId}`).innerHTML = data.content;
      });
  }
  return true;
}
