
initRoutingNumber = function initRoutingNumber(prefix, country_code, routing_url, wire, ach) {
  console.log(`Initiating routing number for: ${prefix} with country code: ${country_code} and only_wire: ${wire} and only_ach ${ach}`)
  
  function getRoutingNumberAcceptance(wire, ach, bankInfo) {
    let acceptedRoutingNumber = true;
    if (wire) {
      if (bankInfo.wire) {
          acceptedRoutingNumber = false;
      }
    }
    if (ach) {
        if (bankInfo.ach) {
            acceptedRoutingNumber = false;
        }
    }
    return acceptedRoutingNumber;
  }
  
  function updateRoutingErrorType(wire_radio, ach_radio, bankData) {
    if (bankData) {
      this.showRoutingErrorType = getRoutingNumberAcceptance(wire_radio.checked, ach_radio.checked, bankData.routing_number_information);
      this.showRoutingSuccess = !this.showRoutingErrorType;
    }
  }
  return {
    country: country_code,
    showRoutingError: false,
    showRoutingErrorType: false,
    showRoutingSuccess: false,
    isRoutingProcessing: false,
    hasAppliedData: false,
    isRouting: false,
    bankData: null,
    domain_prefix: prefix,
    routing_url: routing_url,
    applyData($dispatch) {

      const bank_name_selector = document.querySelector(`#${this.domain_prefix}bank_name`);
      const bank_country_code = document.querySelector(`#${this.domain_prefix}bank_country_code`);
      const bank_city = document.querySelector(`#${this.domain_prefix}bank_city`);
      const bank_non_usa_state = document.querySelector(`#${this.domain_prefix}non_domestic_bank_state`);
      const bank_address = document.querySelector(`#${this.domain_prefix}bank_address`);
      const bank_zipcode = document.querySelector(`#${this.domain_prefix}bank_zipcode`);
      const wire_radio = document.querySelector(`#${this.domain_prefix}wire`);
      const ach_radio = document.querySelector(`#${this.domain_prefix}ach`);
      this.hasAppliedData = true;

      if (this.bankData.is_routing_number && this.bankData.routing_number_information) {
        const bankData = this.bankData.routing_number_information;
        if (bank_name_selector) {
          bank_name_selector.value = bankData.bank_name;
        }
        if (bank_country_code) {
          bank_country_code.value = 'US';

        }
        this.country = 'US';
        bank_city.value = bankData.city;
        const state = bankData.state;
       
        setTimeout(() => {
          const bank_usa_state = document.querySelector(`#${this.domain_prefix}bank_state`);
          if (bank_usa_state) {
            bank_usa_state.value = state;
            bank_usa_state.dispatchEvent(new Event('change'));
          }
          bank_country_code.dispatchEvent(new Event('change'));
        }, 3000)
        return;
      }
      if (this.bankData.swift_code_information) {
        const bankData = this.bankData.swift_code_information;
        bank_name_selector.value = bankData.bank.name;
        if (wire_radio) {
          ach_radio.checked = false;
          wire_radio.checked = true;
        }
        if (bank_country_code) {
          bank_country_code.value = bankData.country.id;
          bank_country_code.dispatchEvent(new Event('change'));
        }
        if (bank_city) {
          bank_city.value = bankData.city.name;
        }
        if (bank_zipcode) {
          bank_zipcode.value = bankData.postcode;
        }
        if (bank_address) {
          bank_address.value = bankData.address;
        }
        this.country = bankData.country.id;
        const state = bankData.city.name.toUpperCase().substring(0, 2);

        setTimeout(() => {

          if (bank_non_usa_state) {
            bank_non_usa_state.value = state;
          }
        }, 100)
        return;
      }
    },
    clearData() {
      const bank_name_selector = document.querySelector(`#${this.domain_prefix}bank_name`);
      const bank_country_code = document.querySelector(`#${this.domain_prefix}bank_country_code`);
      const bank_city = document.querySelector(`#${this.domain_prefix}bank_city`);
      const bank_usa_state = document.querySelector(`#${this.domain_prefix}bank_state`);
      const bank_non_usa_state = document.querySelector(`#${this.domain_prefix}non_domestic_bank_state`);
      const bank_address = document.querySelector(`#${this.domain_prefix}bank_address`);
      const bank_zipcode = document.querySelector(`#${this.domain_prefix}bank_zipcode`);
      bank_name_selector.value = '';
      bank_country_code.value = '';
      if (bank_city) {
        bank_city.value = '';
      }
      if (bank_usa_state) {
        bank_usa_state.value = '';
      }
      if (bank_non_usa_state) {
        bank_non_usa_state.value = '';
      }
      if (bank_address) {
        bank_address.value = '';
      }
      if (bank_zipcode) {
        bank_zipcode.value = '';
      }
    },
    validateRoutingNumber() {
      this.isRoutingProcessing = true;
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const formData = new FormData();
      console.log(`FETCHING ROUTING NUMBER FOR: #${this.domain_prefix}routing_number`);
      const target_routing_number = document.querySelector(`#${this.domain_prefix}routing_number`).value;
      const wire_radio = document.querySelector(`#${this.domain_prefix}wire`);
      const ach_radio = document.querySelector(`#${this.domain_prefix}ach`);
      if (ach_radio !== null && wire_radio !== null) {
        wire_radio.addEventListener("change", () => {
          updateRoutingErrorType.bind(this)(wire_radio, ach_radio, this.bankData)
        });
        ach_radio.addEventListener("change", () => {
          updateRoutingErrorType.bind(this)(wire_radio, ach_radio, this.bankData)
        });
      } 

      formData.append('routing_number', target_routing_number);

      function verifySuccess() {
        this.showRoutingError = false;
        
        const wire_radio = document.querySelector(`#${this.domain_prefix}wire`);
        const ach_radio = document.querySelector(`#${this.domain_prefix}ach`);
        const wireValue = wire_radio ? wire_radio.checked : wire;
        const achValue = ach_radio ? ach_radio.checked : ach;
        this.showRoutingErrorType = getRoutingNumberAcceptance(wireValue, achValue, this.bankData.routing_number_information);
        this.showRoutingSuccess = !this.showRoutingErrorType;
      }

      function verifyError(error) {
        console.error(error);
        this.showRoutingError = true;
        this.showRoutingSuccess = false;
      }

      function processData(data) {
        console.log(`processData(${JSON.stringify(data)})`)
        if (data.is_routing_number != null) {
          this.isRouting = data.is_routing_number && data.routing_number_information
          this.bankData = data;
          return verifySuccess.call(this);
        }
        verifyError.call(this);
      }

      function processRaw(raw) {
        this.isRoutingProcessing = false;
        console.log(`processRaw(${JSON.stringify(raw)})`)
        console.log(JSON.stringify(raw));
        if (raw.status !== 200) {
          throw new Error(raw);
        }
        return raw.json();
      }
      if (this.hasAppliedData) {
        this.clearData();
      }

      fetch(this.routing_url, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': token
        }
      }).then(processRaw.bind(this))
        .then(processData.bind(this))
        .catch(verifyError.bind(this))
        .finally(() => {

        })
    }
  }
}
